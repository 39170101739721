import Vue from "vue";
import VueResource from "vue-resource";
import VueI18n from "vue-i18n";
import VueSwal from "vue-swal";
import Toasted from "vue-toasted";
import PortalVue from "portal-vue";
import VueTour from "vue-tour";
import "vue-tour/dist/vue-tour.css";

// https://vue-select.org/
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

import { abilitiesPlugin } from "@casl/vue";
import { formatter } from "@/utils/data-formatter.js";

import App from "./App.vue";
import VueAnalytics from "vue-analytics";
import router from "./router";
import store from "./store";

import ability from "./project/base-ability";
import Utils from "./plugins/utils.js";
import DateTime from "./plugins/datetime.js";
import FeatureToggle from "./plugins/feature-toggle";
import MixinPermission from "./project/mixin-permission";
import MixinTour from "./project/mixin-tour";

import DashboardService from "@/services/dashboard";
import { loadCSS, applyCSSTheme } from "@/plugins/utils";

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["iframe"];
Vue.config.packageVersion = process.env.VUE_APP_VERSION || "0";

Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(VueSwal);
Vue.use(Utils);
Vue.use(DateTime);
Vue.use(Toasted);
Vue.use(abilitiesPlugin, ability);
Vue.use(PortalVue);
Vue.use(VueTour);
Vue.use(FeatureToggle);
Vue.component("v-select", vSelect);

// implement timeout error handling
Vue.http.interceptors.push((request, next) => {
  // Use _timeout instead
  if (request._timeout) {
    setTimeout(() => {
      //Customize the response body status:408,statustext:"request timeout", and return to the next below
      next(
        request.respondWith(request.body, {
          status: 408,
          statusText: " Request timed out "
        })
      );
    }, request._timeout);
  }
  next((response) => {
    //console.log(response.status)//If overtime output 408
    return response;
  });
});

const updatePageTitle = async (vm, to) => {
  const cfg = vm?.$root?.config || {};
  var company =
    (cfg && "company_name" in cfg && cfg?.company_name) || "no name";
  var pageTitle = (to && to.meta.title && vm.$t(to.meta.title)) || "";
  company = company.indexOf("<") >= 0 ? $(company).text() : company;
  var title = pageTitle ? `${company} - ${pageTitle}` : company;
  if (title !== document.title) {
    document.title = title;
  }
};

//moment
momentDurationFormatSetup(moment);
import en from "./i18n/en";
import pt from "./i18n/pt";
import momentLocales from "./i18n/moment";

let srv = new DashboardService();
srv.setup().then((config) => {
  if (config) {
    if (config.css_theme) {
      applyCSSTheme(config.css_theme);
    }
    if (config.css_file) {
      loadCSS(config.css_file);
    }

    //===================================================
    // google analytics
    if (!config.dev && "ga" in config && config.ga) {
      Vue.use(VueAnalytics, { id: config.ga, router });
    }
    //===================================================
    // locale
    let default_lc = (
      navigator.language ||
      navigator.userLanguage ||
      "en"
    ).split("-")[0];
    if ("language" in config && config.language) {
      default_lc = config.language; // up to now there is no configurable user_profile - so force it to portuguese till be able to change and save it
    }
    let i18n = new VueI18n({
      locale: default_lc, // set locale
      fallbackLocale: "en",
      messages: {
        en: en,
        pt: pt
      }
    });

    // set moment customization for locales

    momentLocales.forEach(({ locale, customization }) =>
      moment.locale(locale, customization)
    );
    moment.locale(default_lc);
    //===================================================
    // vue instance
    new Vue({
      mixins: [MixinPermission, MixinTour],
      data() {
        return {
          config: config // access in components with this.$root.config
        };
      },
      router,
      store,
      i18n,
      render: (h) => h(App),
      beforeCreate() {
        this.$i18n.silentTranslationWarn = true;
      },
      created: function() {
        this.$router.vm = this;
        if (this.config && "favicon" in this.config && this.config.favicon) {
          this.$utils.favicon(this.config.favicon);
        }
        this.$store.dispatch("dashboard/init");
        if (window.Cypress) {
          window.__store__ = this.$store;
          window.__config__ = this.$root.config;
        }
        this.$utils.initCookieEvents();
        this.$formatter = formatter(this);
      },
      watch: {
        $route: {
          immediate: true,
          handler(to) {
            updatePageTitle(this, to);
          }
        }
      }
    }).$mount("#app");
  } else {
    //console.log("/index.json not found");
  }
});
